import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../constants';
import { Observable} from 'rxjs';
import { DrivingLicenseModel, DrivingLicenseOculistModel, DrivingLicenseOtolaryngologistModel } from 'app/attestations/attestations-model';
import { DiseaseModel, RestrictionCodesModel } from 'app/attestations/driving-license/driving-license-model';


@Injectable({
  providedIn: 'root'
})
export class AnamnesisService {

  constructor(private http: HttpClient) { }

  url = environment.apiUrl;

  getAvailableDriversLicenseAnamnesis(id: string): Observable<DrivingLicenseModel> {
    return this.http.get<DrivingLicenseModel>(`${this.url}` + 'DriversLicensePermissionAnamnesis/getByPatientNid/'+id);
  }

  getDriversLicenseAnamnesisById(id: string): Observable<DrivingLicenseModel> {
    return this.http.get<DrivingLicenseModel>(`${this.url}` + 'DriversLicensePermissionAnamnesis/getById/'+id);
  }

  getOrlDriversLicenseAnamnesisById(nid: string): Observable<DrivingLicenseOtolaryngologistModel> {
    return this.http.get<DrivingLicenseOtolaryngologistModel>(`${this.url}` + 'DriversLicenseAnamnesisOrl/getById/'+nid);
  }

  addOrlData(data: DrivingLicenseOtolaryngologistModel): Observable<DrivingLicenseOtolaryngologistModel> {
      const headers = new HttpHeaders().set('content-type', 'application/json');
      var body = JSON.stringify(data);
      return this.http.post<DrivingLicenseOtolaryngologistModel>(`${this.url}` + 'DriversLicenseAnamnesisOrl/', body, {
        headers
      })
  }

  updateOrlData(data: DrivingLicenseOtolaryngologistModel): Observable<DrivingLicenseOtolaryngologistModel> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = JSON.stringify(data);
    return this.http.post<DrivingLicenseOtolaryngologistModel>(`${this.url}` + 'DriversLicenseAnamnesisOrl/update/', body, {
      headers
    })
  }

  // oculist services
  getOculistDriversLicenseAnamnesisById(id: string): Observable<DrivingLicenseOculistModel> {
    return this.http.get<DrivingLicenseOculistModel>(`${this.url}` + 'DriversLicenseOculistAnamnesis/getById/'+id);
  }

  addOculistData(data: DrivingLicenseOculistModel): Observable<DrivingLicenseOculistModel> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = JSON.stringify(data);
    return this.http.post<DrivingLicenseOculistModel>(`${this.url}` + 'DriversLicenseOculistAnamnesis/', body, {
      headers
    })
  }
  
  updateOculistData(data: DrivingLicenseOculistModel): Observable<DrivingLicenseOculistModel> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = JSON.stringify(data);
    return this.http.post<DrivingLicenseOculistModel>(`${this.url}` + 'DriversLicenseOculistAnamnesis/update/', body, {
      headers
    })
  }

  getRestrictionCodes(restrictionType: number) {
    return this.http.get<RestrictionCodesModel[]>(`${this.url}` + 'RestrictionCode/getAllByRestrictionType?restrictionType='+restrictionType);
  }

  getRequirementsByType(type: number) {
    return this.http.get<DiseaseModel[]>(`${this.url}` + 'DriversLicenseCertificateDisease/getAllByTypeAndSection?diseaseSection='+type);
  }
  
}

